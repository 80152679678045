<template>
  <div class="confirm-order">
    <div class="header">
      <img src="../assets/header.jpg" alt="">
    </div>
    <main>
      <div class="return" @click="goBack"><van-icon name="arrow-left" /><span>兑换码填写</span></div>
      <div class="main">
        <div class="part1 part">
          <h2>订单确认</h2>
          <ul>
            <li><span>服务机场：</span><span>{{ airport }}</span></li>
            <li><span>服务时间：</span><span>{{ orderForm.memberServiceOrder.usageTime}}</span></li>
            <li><span>出行人数：</span><span>{{ orderForm.consumerList.length}}人</span></li>
            <li><span>总联系人：</span><span>{{ orderForm.memberServiceOrder.linkmanTel}}</span></li>
          </ul>
        </div>
        <div class="part part2">
          <div class="info" v-for="item in orderForm.consumerList" :key="item.index">
            <h2>乘客信息</h2>
            <ul>
              <li><span>姓名：</span><span>{{ item.name }}</span></li>
              <li><span>联系电话：</span><span>{{ item.tel }}</span></li>
              <li><span>身份证：</span><span>{{ item.idCardNum }}</span></li>
              <li><span>兑换码：</span></li>
              <li><span class="code">{{ item.pwdCode }}</span><img v-show="item.pwdCode" src="../assets/yes.png" alt=""></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="cost">
        <div class="btn">
          <div class="lt">总价：<span class="mount">¥ {{ orderForm.memberServiceOrder.realAmount }}</span><span class="allmount" v-if="orderForm.memberServiceOrder.realAmount != orderForm.memberServiceOrder.totalAmount">¥ {{ orderForm.memberServiceOrder.totalAmount }}</span></div>
          <div class="rt"><span @click="showPriceDetail">明细<van-icon :name="iconArrow" /></span><button @click="submit">支付</button></div>
        </div>
        <div class="define" v-show="moreshow">
          <h4>价格明细</h4>
          <ul>
            <li><span>单价:</span>------------------------------<span>¥ {{ parice }}</span></li>
            <li><span>人数:</span>------------------------------<span>{{ orderForm.consumerList.length }}人</span></li>
            <li><span>兑换码:</span>------------------------------<span>{{ pwdNum }}张</span></li>
          </ul>
        </div>
      </div>
    </main>
    <div class="footer">
      <img src="../assets/footer.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmOrder',
  data () {
    return {
      iconArrow: 'arrow-up',
      moreshow: false,
      orderForm: {
        memberServiceOrder:{},
        consumerList:[]
      },
      airport: '',
      pwdNum: 0,
      parice: 0,
      room: ''
    }
  },
  mounted () {
    this.orderForm = JSON.parse(this.$route.query.user)
    this.room = this.$route.query.room
    this.airport = this.$route.query.airport
    for(var i = 0; i < this.orderForm.consumerList.length; i++) {
      if(this.orderForm.consumerList[i].pwdCode){
        this.pwdNum ++
      }
      this.parice = this.orderForm.consumerList[i].costAmount
    }
  },
  methods: {
    // 显示价格明细
    showPriceDetail() {
      this.moreshow = !this.moreshow
      if(this.moreshow){
        this.iconArrow = 'arrow-down'
      }else{
        this.iconArrow = 'arrow-up'
      }
    },
    goBack() {
      this.$route.params.airport = JSON.stringify(this.airport)
      this.$route.params.room = JSON.stringify(this.room)
      this.$route.params.info = JSON.stringify(this.orderForm)
      this.$router.back()
    },
    submit() {
      // 下单
      console.log('用户数据',this.orderForm)
      // console.log(this.orderForm.memberServiceOrder.linkmanTel)
      let that = this
      this.request({
        url:"/order/order/member-service-order/H5SaveOrder.json",
        method: "POST",
        data: this.orderForm
      }).then(res=>{
        console.log("订单信息：",res)
        if(res.code == 200) {
          if(res.data.orderMaster.totalAmount == 0){
            this.$toast.loading({
              message: '支付中...',
              forbidClick: true,
            })
            setInterval(() => {
              this.$router.push({
                path: '/paySuccess',
              })
            }, 3000)
          }else{
            this.$router.push({
              path: '/pay',
              query: {
                phone: that.orderForm.memberServiceOrder.linkmanTel,
                orderNo: res.data.orderMaster.profOrderNo,
                cost: res.data.orderMaster.totalAmount
              }
            })
          }
        }else{
          this.$toast(res.msg)
        }
      })
      .catch(err => {
        console.log(err)
        // this.$toast(err.msg)
      })
    }
  }
}
</script>

<style lang="less">
.confirm-order{
  .header, .footer{
    width: 100%;
    height: 167px;
    img{
      width: 100%;
      height: 100%;
    }
  }

  main{
    background: #f2f2f2;
    .return{
      height: 48px;
      font-size: 20px;
      display: flex;
      padding: 0 10px;
      box-sizing: border-box;
      .van-icon{
        font-size: 28px;
        font-weight: bold;
        align-self: center;
      }
      span{
        align-self: center;
      }
    }
    .main{
      padding: 0 20px 10px;
      box-sizing: border-box;

      .part{
        background: #fff;
        border-radius: 15px;
        padding: 0 15px 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
      }

      .part1{
        h2{
          font-size: 18px;
          font-weight: bold;
          padding: 8px 0;
          box-sizing: border-box;
          border-bottom: 1px solid #aaaaaa;
          margin-bottom: 5px;
        }
        ul{
          li{
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            height: 30px;
            padding-left: 5px;
            box-sizing: border-box;

            >span{
              align-self: center;
            }
          }
        }
      }
      .part2{
        padding: 0 18px;
        .info{
          padding-bottom: 15px;
          box-sizing: border-box;
          border-bottom: 1px solid #000;
          h2{
            font-size: 18px;
            font-weight: bold;
            padding: 5px 0;
            box-sizing: border-box;
            padding-left: 2px;
            box-sizing: border-box;
          }
          ul{
            padding-left: 5px;
            box-sizing: border-box;
            li{
              display: flex;
              justify-content: space-between;
              font-size: 16px;
              height: 30px;
              box-sizing: border-box;

              >span{
                align-self: center;
              }
              .code{
                width: 80%;
                padding-bottom: 1px;
                padding-right: 5px;
                box-sizing: border-box;
                border-bottom: 1px solid #000;
              }

              img{
                width: 18px;
                height: 18px;
                align-self: center;
              }
            }
          }
        }
        >:last-child{
          border: 0;
        }
      }

      >:last-child{
        margin: 0;
      }
    }
    
    .cost{
      position: relative;
      .btn{
        height: 50px;
        background: #fff;
        border-radius: 15px 15px 0 0;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgb(161, 161, 161);

        >div{
          align-self: center;
        }

        .lt{
          .mount{
            color: red;
            margin-right: 10px;
          }
          .allmount{
            color: #7f7f7f;
            text-decoration: line-through;
          }
        }

        .rt{
          span{
            font-size: 12px;
            color: #81d3f8;
          }
          button{
            color: #fff;
            font-size: 14px;
            background: #169bd5;
            border-radius: 20px;
            padding: 3px 20px;
            margin-left: 5px;
          }
        }
      }
    }
    .define{
      position: absolute;
      bottom: 51px;
      z-index: 9;
      left: 0;
      width: 100%;
      background: #fff;
      box-shadow: 0px -8px 13px rgb(219, 219, 219);

      h4{
        text-align: center;
        padding: 7px 0 5px;
        // border-bottom: 1px solid #999;
        margin-bottom: 3px;
        font-weight: bold;
      }

      ul{
        padding: 0px 30px 10px;

        li{
          display: flex;
          justify-content: space-between;
          height: 25px;
          font-size: 14px;
          line-height: 25px;

          span{
            align-self: center;
          }
          :last-child{
            color: red;
          }
        }
      }
    }
  }
}
</style>
