import Vue from 'vue'
import {
  Stepper,
  Button,
  Icon,
  Popup,
  Calendar,
  Cell,
  Field,
  Form,
  Toast,
  Picker,
  DatetimePicker,
  Dialog,
  Radio,
  RadioGroup,
  Grid,
  GridItem,
  CountDown 
} from 'vant'


Vue.use(Stepper)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(Calendar)
Vue.use(Cell)
Vue.use(Field)
Vue.use(Form)
Vue.use(Toast)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(CountDown )