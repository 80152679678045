<template>
  <div class="reserve">
    <div class="header">
      <img src="../assets/header.jpg" alt="">
    </div>
    <main>
      <div class="plate plate1">
        <div class="airport item">
          <van-cell title="机场选择:" :value="airportName" @click="airportShow = true" :value-class="(airportName == '请选择机场') ? 'placol' : 'valcol'" />
          <van-icon name="arrow" />
          <van-popup v-model="airportShow" closeable position="bottom" :style="{ height: '70%' }">
            <h2>请选择机场</h2>
            <div>
              <van-icon name="search" />
              <input type="text" v-model="placekey" placeholder="搜索">
            </div>
            <ul>
              <li
                v-for="item in (searchList.length > 0 ? searchList : airportList)"
                :key="item.index"
                @click="checkAirport(item)"
              >{{ item.airportName }}</li>
            </ul>
          </van-popup>
        </div>
        <div class="date item">
          <van-cell title="日期选择:" :value="orderForm.memberServiceOrder.usageTime" @click="Calendershow = true" :value-class="(orderForm.memberServiceOrder.usageTime == '请选择日期') ? 'placol' : 'valcol'" />
          <van-icon name="arrow" />
          <van-calendar
            v-model="Calendershow"
            color="#0084f4"
            :show-confirm="false"
            @confirm="checkDate"
            :max-date="maxData"
          />
        </div>
        <div class="viphall item">
          <van-cell title="贵宾厅选择:" :value="memberServiceName" @click="queryMemberService()" :value-class="(memberServiceName == '请选择贵宾厅') ? 'placol' : 'valcol'" />
          <van-icon name="arrow" />
          <van-popup v-model="memberServiceShow" closeable position="bottom" :style="{ height: '70%' }">
            <h2>请选择贵宾厅</h2>
            <div v-if="false">
              <van-icon name="search" />
              <input type="text" v-model="placekey" placeholder="请选择贵宾厅">
            </div>
            <ul v-if="memberServiceList.length != 0">
              <li
                v-for="item in memberServiceList"
                :key="item.index"
                @click="checkViphall(item)"
              >{{ item.productName }}</li>
            </ul>
            <p class="nothing" v-if="memberServiceList.length == 0">当前机场暂未提供贵宾服务，请重新选择！</p>
          </van-popup>
        </div>
      </div>
      <!-- 总联系人 -->
      <div class="plate plate2">
        <div class="top">
          <van-field
            v-model="orderForm.memberServiceOrder.linkmanTel"
            label="总联系人："
            type="digit"
            maxlength="11"
            placeholder="请输入手机号"
            :rules="[{ pattern: phoneReg, message: '请输入正确的手机号' }]"
            @blur="testPhone(orderForm.memberServiceOrder.linkmanTel)"
          />
        </div>
        <div class="bot">(注：总联系人用于接收订单信息和核销验证码)</div>
      </div>
      <!-- 乘客信息 -->
      <div class="plate plate3" v-for="(item, index) in orderForm.consumerList" :key="item.index">
        <div class="tit"><span>乘客信息</span><span @click="deletePassenger(index)">删除乘客</span></div>
        <van-form>
          <div>
            <span>*</span>
            <van-field v-model="item.name" label="乘客姓名：" @blur="checkFlag(item)" placeholder="请输入姓名" />
          </div>
          <div>
            <span>*</span>
            <van-field v-model="item.tel" label="联系电话：" type="digit" maxlength="11" placeholder="请输入手机号" :rules="[{ pattern: phoneReg, message: '请输入正确的手机号' }]" @blur="checkFlag(item)" />
          </div>
          <div>
            <span>*</span>
            <van-field v-model="item.idCardNum" label="身份证号码：" placeholder="请输入身份证" :rules="[{ pattern: idCardReg, message: '请输入正确的身份证号！' }]" @blur="checkFlag(item)"  />
          </div>
          <div class="reCode">
            <div>兑换码：</div>
            <div class="inp">
              <input type="text" v-model="item.pwdCode" placeholder="请输入兑换码" @blur="checkRedeemCode(item.pwdCode, item)" >
            </div>
          </div>
          <div class="err">
            <img v-if="!item.pwdCodeFlag && item.pwdCodeErr != ''" src="../assets/no.png" alt="">
            <img v-if="item.pwdCodeFlag && item.pwdCodeErr != ''" src="../assets/yes.png" alt="">
            <span :class="item.pwdCodeFlag ? 'colgreen' : ''">{{ item.pwdCodeErr }}</span>
          </div>
           
        </van-form>
      </div>
      <div class="addBtn" @click="handleAddPassenger">
        <span>添加乘客...</span>
        <van-icon name="add" />
      </div>
      <div class="subBtn">
        <button @click="reverse">立即预约</button>
      </div>
    </main>
    <div class="footer">
      <img src="../assets/footer.jpg" alt="">
    </div>
  </div>
</template>

<script>
import moment from 'moment';
let previousRouterName = ''
let info = ''
let port = ''
let serviceRoom = ''
export default {
  name: 'Reserve',
  data () {
    return {
      // 提交的表单
      orderForm:{
        memberServiceOrder:{
          memberServiceId:'',//产品ID
          usageTime:'',//预定时间
          memberNo:"",//会员号
          linkmanTel:'',//联系人
          // totalAmount costAmount orderAmount 相等 
          totalAmount:0,//会员价*人数 costAmount+=
          realAmount:0,//会员价*人数 ，抵扣了则为 0 totalAmount+=
          costAmount:0,//市场价*人数 costAmount 原价marketPrice+=
          orderAmount:0//订单实际金额（会员单价*人数）=totalAmount
        },//主订单
        consumerList:[
          {
            name :'',
            nameFlag: false,
            tel:'',
            telFlag: false,
            idCardNum:'',
            idCardType:1,
            idCardNumFlag: false,
            pwdCode:'',
            pwdCodeFlag: false, // 为ture时正确，为false时错误(显示)
            pwdCodeErr: '',
            // costAmount:'', // costPrice
            // totalAmount:'',// 抵扣了则为 0
          }
        ],//使用人
      },
      num: '',
      airportName:'',
      airportCode: '',//三字码
      airportShow: false,
      airportList: [], // 机场列表
      memberServiceName: '',
      memberServiceShow: false,
      memberServiceList: [], // 贵宾厅列表
      placekey: '',
      searchList: '', // 搜索列表
      Calendershow: false,
      maxData: new Date(2100, 0, 1),
      phoneFlag: false,
      phoneReg: /^[1][3,4,5,7,8,9][0-9]{9}$/,
      idCardReg: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, // 验证身份证
      marketPrice: '', // 市场价,
      vipRoomInfo: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    previousRouterName = from.name
    if (from.name === "confirmOrder") {
      info = from.params.info
      // port = from.params.airport
      // serviceRoom = from.params.room
    }
    next()
  },
  created() {
    if (previousRouterName === "confirmOrder") {
      // let str = JSON.parse(port)
      let obj = JSON.parse(info)
      // let rom = JSON.parse(serviceRoom)
      // this.airportName = str
      this.orderForm = obj
      // this.memberServiceName = rom
    }
  },
  mounted () {
    this.num = Number(this.$route.query.num)
    for (var i = 0; i < this.num -1; i++) {
      this.handleAddPassenger()
    }
    if(this.$route.query.vipId){
      this.orderForm.memberServiceOrder.memberNo = this.$route.query.vipId
    }
    if(this.$route.query.pwdCode){
      let arr = JSON.parse(this.$route.query.pwdCode)
      for(var i = 0; i < arr.length-1; i++){
        this.handleAddPassenger()
      }
      for(var i =0; i < arr.length; i++){
        this.orderForm.consumerList[i].pwdCode = arr[i]
      }
    }
    this.queryUserAirportNew()
  },
  methods: {
    /* 获取机场 */
    queryUserAirportNew(){
      this.request({
        url:"/product/sdAirportNew/queryUserAirportNew",
        method: "get",
        params:{
          type:1
        }
      }).then(resp=>{
          // console.log("190",resp);
          if(resp.code == 200){
            this.airportList = resp.data
          }
      })
    },
    /* 获取贵宾厅 */
    queryMemberService(){
      console.log('贵宾厅选择参数',this.airportCode)
      this.request({
        url:"/product/productInfo/getProductByAirCodeAndTime",
        method: "get",
        params:{
          vestedFirm:1,
          airPortCode:this.airportCode,//机场三字码
          bookingTime: this.orderForm.memberServiceOrder.usageTime//使用时间
        }
      }).then(res=>{
        console.log("190",res)
        if(res.code == 200){
          this.memberServiceList = res.data
          this.memberServiceShow = true
        }
      })
    },
    /* 校验兑换码 */
    checkRedeemCode(code, obj){
      if('' != code  && null != code){
        this.request({
          url:"/product/rcMemberProduct/useProduct/byCode",
          method: "get",
          params:{
            code:code,
            productId:this.orderForm.memberServiceOrder.memberServiceId,//机场三字码
            useTime:this.orderForm.memberServiceOrder.usageTime//使用时间
          }
        }).then(resp=>{
          // console.log("221",resp)
          if(resp.code == 200){
            obj.pwdCodeFlag = true
            obj.pwdCodeErr = '兑换码可用'
            obj.totalAmount = 0
          }else{
            obj.pwdCodeFlag = false
            this.$toast(resp.msg) // 需要反馈到页面上
            obj.pwdCodeErr = resp.msg
          }
        })
      }else{
        obj.pwdCodeFlag = false
        obj.pwdCodeErr = ''
      }
    },
    // 选择机场
    checkAirport(item){
      // console.log(item)
      this.airportName = item.airportName
      this.airportShow = false
      this.airportCode = item.threeWord
    },
    // 选择贵宾厅
    checkViphall (item) {
      console.log("209",item)
      this.memberServiceShow = false
      this.memberServiceName = item.productName
      this.orderForm.memberServiceOrder.memberServiceId = item.id
      this.vipRoomInfo = item
      this.marketPrice = item.priceData[0].marketPrice
    },
    // 机场搜索
    getSearchPlace(keywords) {
      this.searchList = []
      let countryList = this.airportList
      let searchList = countryList.filter(
        (ft) =>
          ft.airportName.indexOf(keywords) > -1
          // || ft.threeWord.toUpperCase().startsWith(keywords.toUpperCase()) ||
          // (ft.ename && ft.ename.toUpperCase().startsWith(keywords.toUpperCase()))
      )
      if (searchList && searchList.length > 0) {
        this.searchList = searchList
      } else {
        this.searchList = [{ airportName: '无结果' }]
      }
    },
    // 选择日期
    checkDate(bookingTime) {
      let time = moment(bookingTime).format('YYYY-MM-DD')
      this.Calendershow = false
      
      console.log(time)
      this.orderForm.memberServiceOrder.usageTime = time;
    },
    testPhone (num) {
      if(this.orderForm.memberServiceOrder.linkmanTel && this.phoneReg.test(num)){
        this.phoneFlag = true
      }else{
        this.$toast('请输入正确的电话号码！')
      }
    },
    // 添加乘客
    handleAddPassenger() {
      let item = {
        name :'',
        nameFlag: false,
        tel:'',
        telFlag: false,
        idCardNum:'',
        idCardType:1,
        idCardNumFlag: false,
        pwdCode:'',
        pwdCodeFlag: false, // 为false时正确，为ture时错误(显示)
        pwdCodeErr: ''
      }
      this.orderForm.consumerList.push(item)
    },
    // 删除乘客
    deletePassenger (idx) {
      this.orderForm.consumerList.splice(idx, 1)
    },
    checkFlag(item){
      if(item.name){
        item.nameFlag = true
      }else{
        item.nameFlag = false
      }
      if(item.tel && this.phoneReg.test(item.tel)){
        item.telFlag = true
      }else{
        item.telFlag = false
      }
      if(item.idCardNum && this.idCardReg.test(item.idCardNum)){
        item.idCardNumFlag = true
      }else{
        item.idCardNumFlag = false
      }
    },
    pasgerFlag (item) {
      if(!item.name){
        this.$toast('请填写姓名')
        return false
      }
      if(!item.phone){
        this.$toast('请填写手机号')
        return false
      }
      if(!item.idCard){
        this.$toast('请填写身份证号')
        return false
      }
    },
    reverse () {
      console.log('最初的数据',this.orderForm)
       let obj = this.orderForm
      if(!obj.memberServiceOrder.memberServiceId || !this.vipRoomInfo){
        this.$toast('请先选择机场、日期以及贵宾厅！')
        return
      }
      for(var i = 0; i < this.orderForm.consumerList.length; i++){
        if(this.orderForm.consumerList[i].pwdCode){
          this.checkRedeemCode(this.orderForm.consumerList[i].pwdCode, this.orderForm.consumerList[i])
          if(this.orderForm.consumerList[i].pwdCodeFlag == false){
            return
          }
        }
        this.orderForm.consumerList[i].costAmount = this.vipRoomInfo.priceData[0].costPrice
        // 没有兑换码 
        if(this.orderForm.consumerList[i].totalAmount!=0){
          this.orderForm.consumerList[i].totalAmount = this.vipRoomInfo.priceData[0].costPrice
        }
      }
      this.orderForm.memberServiceOrder.totalAmount = 0
      this.orderForm.memberServiceOrder.realAmount = 0
      this.orderForm.memberServiceOrder.costAmount = 0
      this.orderForm.memberServiceOrder.orderAmount = 0
     
      let arr = this.orderForm.consumerList
      if(this.phoneReg.test(this.orderForm.memberServiceOrder.linkmanTel)){
        this.phoneFlag = true
      }
      // 计算价格
      if(this.phoneFlag){
        for(var i = 0; i < arr.length; i++) {
          if(!arr[i].nameFlag || !arr[i].telFlag || !arr[i].idCardNumFlag){
            this.$toast('请确认数据填写完整,如若出行乘客人数不足，可删除多余乘客信息')
            return
          }
          // else{
          //   this.orderForm.memberServiceOrder.totalAmount += arr[i].costAmount
          // }
        }
        console.log(111111,JSON.parse(JSON.stringify(arr)))
        arr.filter(fl => {
          console.log(fl,fl.totalAmount)
          this.orderForm.memberServiceOrder.totalAmount += fl.costAmount
          this.orderForm.memberServiceOrder.realAmount += fl.totalAmount
        })
         
        //  与上面的filter结果相同
        // let totalPrice = arr.reduce((initVal,currenVal)=>{
        //   return initVal.totalAmount+currenVal.totalAmount
        // })
        // console.log('totalpRICE',totalPrice)

        this.orderForm.memberServiceOrder.costAmount = this.orderForm.consumerList.length * this.marketPrice
        this.orderForm.memberServiceOrder.orderAmount = this.orderForm.memberServiceOrder.totalAmount

        console.log('最终的数据', this.orderForm)
        this.$router.push({
          path: '/confirmOrder',
          query: {
            user: JSON.stringify(this.orderForm),
            airport: this.airportName,
            room: this.memberServiceName
          }
        })
      }else{
        this.$toast('请填写总联系人手机号')
      }
    }
  },
  watch: {
    placekey: function (val) {
      this.keywords = val
      if (val) {
        this.getSearchPlace(val) 
      } else {
        this.searchList = ''
      }
    }
  }
}
</script>

<style lang="less">
.reserve{
  .header, .footer{
    width: 100%;
    height: 167px;
    img{
      width: 100%;
      height: 100%;
    }
  }

  main{
    width: 100%;
    background: #f2f2f2;
    padding: 12px 15px 15px;
    box-sizing: border-box;

    .plate{
      width: 100%;
      background: #fff;
      border-radius: 15px;
      padding: 0 20px;
      box-sizing: border-box;
      margin-bottom: 7px;
    }
    .plate1{
      // height: 113px;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .item{
        height: 36px;
        display: flex;
        .van-cell{
          font-size: 18px;
          padding: 0;
          border: 0;
          align-self: center;
          
          .van-cell__title{
            width: 100px;
            flex: none;
          }
          .van-cell__value{
            // width: 300px;
            flex: 1;
          }
        }
        .van-cell::after{
          border: 0;
        }
        .van-icon{
          align-self: center;
        }
        .placol{
          color: #999;
        }
        .valcol{
          color: #000;
        }
      }

      .airport{
        .van-popup{
          padding: 0 20px;
          box-sizing: border-box;
          h2{
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            margin-top: 20px;
          }
          div{
            display: flex;
            position: relative;
            .van-icon{
              position: absolute;
              font-size: 24px;
              color: #b4b6c5;
              top: 18px;
              left: 10px;
            }
            input{
              display: inline-block;
              width: 100%;
              height: 40px;
              border-radius: 5px;
              margin: 10px 0;
              padding-left: 40px;
              box-sizing: border-box;
              background: #eeeeee;
            }
          }
          ul{
            margin-top: 10px;
            overflow: scroll;
            height: 355px;
            li{
              height: 40px;
              border-bottom: 1px solid #eeeeee;
              font-size: 16px;
              line-height: 40px;
              color: #333333;
            }
          }
          ul::-webkit-scrollbar  {  
            display: none!important;
            width: 0px;  
            height: 0px;  
          }
        }
      }
      .viphall{
        .van-popup{
          padding: 0 20px;
          box-sizing: border-box;
          h2{
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            margin-top: 20px;
          }
          div{
            display: flex;
            position: relative;
            .van-icon{
              position: absolute;
              font-size: 24px;
              color: #b4b6c5;
              top: 18px;
              left: 10px;
            }
            input{
              display: inline-block;
              width: 100%;
              height: 40px;
              border-radius: 5px;
              margin: 10px 0;
              padding-left: 40px;
              box-sizing: border-box;
              background: #eeeeee;
            }
          }
          ul{
            margin-top: 10px;
            overflow: scroll;
            height: 355px;
            li{
              height: 40px;
              border-bottom: 1px solid #eeeeee;
              font-size: 16px;
              line-height: 40px;
              color: #333333;
            }
          }
          ul::-webkit-scrollbar  {  
            display: none!important;
            width: 0px;  
            height: 0px;  
          }
        }
      }
    }
    .plate2{
      height: 80px;

      .top{
        width: 100%;
        padding-top: 10px;
        border-bottom: 1px solid #000;
        height: 43px;

        .van-cell{
          padding: 0;
          font-size: 18px;
          .van-field__label{
            color: #000;
          }
          .van-cell__value{
            .van-field__control{
              border: 1px solid rgb(49, 47, 47);
              font-size: 14px;
              border-radius: 20px;
              padding-left: 7px;
              box-sizing: border-box;
            }
            .van-field__error-message{
              height: 16px;
              line-height: 16px;
            }
          }
        }
        .van-cell::after{
          border: 0;
        }
      }
      .bot{
        font-size: 14px;
        text-align: center;
      }
    }
    .plate3{
      padding-bottom: 20px;
      .tit{
        border-bottom: 1px solid #000;
        height: 45px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        >:first-child{
          font-size: 18px;
          align-self: center;
        }
        >:last-child{
          font-size: 14px;
          align-self: center;
          color: #02a7f0;
        }
      }
      .van-form {
        >div{
          display: flex;
          >span{
            color: red;
          }
        }
      }
      .van-cell{
        padding: 0;
        font-size: 18px;
        height: 42px;
        .van-field__label{
          color: #000;
        }
        .van-cell__value{
          .van-field__control{
            border: 1px solid rgb(49, 47, 47);
            font-size: 14px;
            border-radius: 20px;
            padding-left: 7px;
            box-sizing: border-box;
          }
          .van-field__error-message{
            height: 16px;
            line-height: 16px;
          }
        }
      }
      .van-cell::after{
        border: 0;
      }

      .reCode{
        >:first-child{
          font-size: 18px;
        }
        .inp{
          display: flex;
          justify-content: space-between;
          >input{
            width: 93%;
            border: 1px solid rgb(49, 47, 47);
            font-size: 14px;
            border-radius: 20px;
            padding-left: 7px;
            box-sizing: border-box;
            height: 26px;
            margin-top: 3px;
            align-self: center;
          }
          ::placeholder{
            color: #d3d4d6;
          }
          img{
            width: 18px;
            height: 18px;
            align-self: center;
          }
        }
      }
      .err{
        padding-left: 20%;
        margin-top: 5px;
        img{
          width: 18px;
          height: 18px;
        }
        .colgreen{
          color: #03bf16;
        }
      }
    }
    .addBtn{
      width: 100%;
      background: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      height: 45px;
      display: flex;
      justify-content: space-between;
      border-radius: 15px;
      font-size: 20px;
      font-weight: bold;
      span,.van-icon{
        align-self: center;
      }
      .van-icon{
        font-size: 30px;
      }
    }
    .subBtn{
      margin-top: 15px;
      padding: 0 20px;
      height: 50px;

      button{
        width: 100%;
        height: 100%;
        background: #1989fa;
        border-radius: 30px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .nothing{
    text-align: center;
    padding-top: 50px;
    color: #666;
  }
}
</style>
