import sha1 from './sha1.js'
import CryptoJS from './aes.js'
const sha1_key=sha1.hex_sha1('WX_HYD_QRCODE_KEY');
const sha1_iv=sha1.hex_sha1('WX_HYD_QRCODE_IV');
const key = CryptoJS.CryptoJS.enc.Utf8.parse(sha1_key); //十六位十六进制数作为秘钥
const iv = CryptoJS.CryptoJS.enc.Utf8.parse(sha1_iv);//十六位十六进制数作为秘钥偏移量
/**
 * aes 加密方法带有偏移量
 */
function AesEncrypt(word) {
  let srcs = CryptoJS.CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.CryptoJS.mode.CBC,
    padding: CryptoJS.CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString().toUpperCase();
}
export default {
  AesEncrypt
}