import axios from "axios"

const service = axios.create({
  // baseURL: ' http://192.168.3.12:7090',// 本地
  // baseURL: 'http://exchangereq.shallsay.cn', // 测试
  baseURL: 'https://conv.hydtrip.net', // 正式服
  timeout: 5000
})


// 请求拦截
service.interceptors.request.use(config => {
  // config.headers = {
    // ...config.headers,
    // 'Authorization': localStorage.getItem('token'),
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Headers": "Authorization,Origin, X-Requested-With, Content-Type, Accept",
    // "Content-Type": 'application/json;charset=UTF-8'
  // }
  return config
})

// 响应拦截，axios对请求接口的数据响应内容做了包装，
// 真正的接口中返回的数据是包装在响应对象的data属性中的
service.interceptors.response.use(res => {
  if(res.status === 200){ // 能够正常获取到数据
    return res.data

    // } else if (res.data.code === 4000 || res.data.code === 5000) { 
         // token 失效或过期相关的状态处理
    //   // 清除 vuex 中保存的用户状态数据
    //   // 清理本地缓存的用户数据
    //   // 跳转到登录页面重新登录验证
    // } else { // 返回的是有错误的数据
    //   const e = new Error('后端接口异常')
    //   e.error = res.data
    //   Promise.reject(e)
    // }
  } else {
    const err = new Error('请求接口失败')
    err.error = res
    Promise.reject(err)
  }
})

export default service