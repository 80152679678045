<template>
  <div class="qrcode-link-hr">
    <h2>海云端&海尔贵宾服务订单</h2>
    <div class="order-card">
      <div class="icon-badge">
        <img src="@/assets/shield-icon.png" alt="shield">
      </div>
      <div class="service-info">
        <h3>{{ productInfo.productName }}</h3>
        <p>{{ productInfo.addressDetail }}</p>
        <div class="detail-link" @click="getIntoDetail">查看详情</div>
      </div>
      <div class="divider"></div>
      <div class="status-info">
        <div class="left-info">
          <div class="status">{{orderInfo.orderStatusText}}</div>
          <div class="valid-period">
            <span>有效期至：</span>
            <span>{{orderInfo.useEndTime}}</span>
          </div>
        </div>
        <div class="lounge-image" v-if="img && !imgLoadError">
          <img :src="img" alt="贵宾厅" @error="handleImgError">
        </div>
      </div>
      <div class="divider"></div>
      <div class="redeem-section">
        <div class="tit">
          <div>核销码：</div>
          <div class="detail-btn" @click="showCode=!showCode">查看详情 ></div>
        </div>
        <div class="code" v-show="showCode">
          <div class="qrcode" ref="qrCodeUrl"></div>
          <div class="refresh-btn" @click="refresh(orderInfo.applyCode)">
            <img src="@/assets/refresh-icon.svg" alt="refresh" class="refresh-icon" />
            <span>手动刷新</span>
          </div>
          <div class="timer-info">
            <span class="countdown-number">{{ countdown }}</span>
            <span class="countdown-text">S后自动刷新二维码</span>
          </div>
          <div class="code-text">{{ orderInfo.applyCode || '8090DA' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import moment from 'moment'
import aes_util from "@/lib/aes_util.js"

export default {
  name: 'qrCodeLinkHR',
  data () {
    return {
      showCode: true, // 设为true以默认显示二维码
      orderNo: '', // 订单号
      orderInfo: '',
      customerList: [], // 客户信息
      showCustomerList: [], // 客户信息
      img: '', 
      productInfo: '',
      time: 45 * 1000, // 45秒刷新定时器
      countdown: 35, // 用于显示倒计时
      isTimeout: false, //判断二维码是否失效，默认为false
      imgLoadError: false // Added to track image loading error
    }
  },
  mounted() {
    this.orderNo = this.$route.query.orderNo
    this.getOrderInfo()
    this.startCountdown()
  },
  methods: {
    getOrderInfo(){
      this.request({
        url: 'order/order/member-service-order/no?orderNo=' + this.orderNo,
        method: 'GET',
      })
      .then(res => {
        console.log(res)
        if(res.code == 200){
          this.orderInfo = res.data.memberServiceOrder
          if(this.orderInfo){
            this.orderInfo.useStartTime = moment(this.orderInfo.useStartTime).format('YYYY-MM-DD')
            this.orderInfo.useEndTime = moment(this.orderInfo.useEndTime).format('YYYY-MM-DD')
          }
          this.img = res.data.img
          this.customerList = res.data.consumerList
          this.showCustomerList = this.customerList[0]
          this.productInfo = res.data.productInfo
          this.creatQrCode(this.orderInfo.applyCode)
        }
      })
    },
    // 进入详情页
    getIntoDetail () {
      this.$router.push({
        path: '/qrCodeDetail',
        query: {
          info: JSON.stringify(this.productInfo),
          img: this.img
        }
      })
    },
    // 生成二维码
    creatQrCode(val) {
      let longTime = new Date().getTime() + 120 * 1000
      let codeText = {
        type: 1,
        vipId: val,
        longTime: longTime,
      }
      let arr = aes_util.AesEncrypt(JSON.stringify(codeText))
      this.$refs.qrCodeUrl.innerHTML = ""
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: arr, // 需要转换为二维码的内容
          width: 180,
          height: 180,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 刷新二维码
    refresh(val) {
      this.creatQrCode(val)
      this.resetCountdown()
    },
    // 自动刷新
    async timeFresh() {
      await this.getOrderInfo()
      this.resetCountdown()
    },
    // 启动倒计时
    startCountdown() {
      this.countdown = 35
      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1
        } else {
          this.timeFresh()
        }
      }, 1000)
    },
    // 重置倒计时
    resetCountdown() {
      clearInterval(this.countdownInterval)
      this.startCountdown()
    },
    handleImgError() {
      this.imgLoadError = true
    }
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval)
  }
}
</script>

<style lang="less" scoped>
.qrcode-link-hr {
  min-height: 100vh;
  background: #1E90FF;
  padding: 20px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: auto;

  h2 {
    text-align: center;
    font-weight: 800;
    font-size: 22px;
    color: white;
    margin-bottom: 20px;
  }

  .order-card {
    width: 92%;
    background: white;
    border-radius: 15px;
    padding: 20px 15px;
    box-sizing: border-box;
    position: relative;

    .icon-badge {
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 40px;
      background: #1E90FF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      img {
        width: 25px;
        height: 25px;
      }
    }

    .divider {
      height: 1px;
      background: #f0f0f0;
      margin: 12px 0;
      border-bottom: 1px dashed #ddd;
    }

    .service-info {
      margin-top: 10px;
      padding-bottom: 8px;

      h3 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
        text-align: center;
      }

      p {
        color: #666;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 5px;
      }

      .detail-link {
        color: #1E90FF;
        font-size: 14px;
        text-align: right;
        margin-top: 5px;
        cursor: pointer;
      }
    }

    .status-info {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-info {
        flex: 1;
        
        .status {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .valid-period {
          display: flex;
          justify-content: flex-start;
          font-size: 14px;
        }
      }

      .lounge-image {
        width: 120px;
        height: 80px;
        margin-left: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }
    }

    .redeem-section {
      padding-top: 8px;

      .tit {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        font-weight: bold;
        
        .detail-btn {
          color: #1E90FF;
          cursor: pointer;
        }
      }

      .code {
        display: flex;
        flex-direction: column;
        align-items: center;

        .qrcode {
          margin-bottom: 12px;
          
          img {
            width: 180px;
            height: 180px;
          }
        }

        .refresh-btn {
          color: #1E90FF;
          font-size: 14px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .refresh-icon {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }

        .timer-info {
          margin-bottom: 10px;
          font-size: 14px;
          color: #666;

          .countdown-number {
            color: red;
            margin-right: 2px;
          }
          .countdown-text {
            color: red;
          }
        }

        .code-text {
          color: #1E90FF;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style> 