<template>
  <div class="pay-success">
    <!-- <div class="header">
      <img src="../assets/header.jpg" alt="">
    </div> -->
    <div class="main">
      <div class="top">
        <img src="../assets/success.png" alt="">
        <h2>支付成功！</h2>
        <h5>您已支付成功，祝您旅途愉快！</h5>
      </div>
      <!-- <div class="bot"> -->
        <!-- <div><span>联系人：</span><span>方糖</span></div> -->
        <!-- <div><span>联系电话：</span><span>{{ phone }}</span></div>
        <div><span>订单编号：</span><span>{{ cardNo }}</span></div>
      </div> -->
    </div>
    <!-- <div class="footer">
      <img src="../assets/footer.jpg" alt="">
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'PaySuccess',
  data() {
    return {
      phone: '',
      cardNo: ''
    }
  },
  mounted () {
    
  }
}
</script>

<style lang="less">
.pay-success{
  height: 100vh;
  position: relative;
  .header, .footer{
    width: 100%;
    height: 167px;
    background: #cfe;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .footer{
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .main{
    margin-top: 30px;
    .top{
      display: flex;
      flex-direction: column;
      img,h2,h5{
      align-self: center;
      }
      img{
        margin-bottom: 25px;
      }
      h2{
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      h5{
        font-size: 18px;
      }
    }
    .bot{
      padding: 0 0 0 40px;
      box-sizing: border-box;
      margin-top: 50px;
      >div{
        display: flex;
        margin-bottom: 10px;
        :first-child{
          font-weight: bold;
          width: 100px;
        }
      }
    }
  }
}
</style>
