<template>
  <div class="qrcode-detail">
    <img :src="img" alt="">
    <div class="top list">
      <h3>{{ productInfo.productName }}</h3>
      <div><span>服务地址：</span>{{ productInfo.addressDetail }}</div>
      <div><span>营业时间：</span>{{ productInfo.serverStime }}-{{ productInfo.serverEtime }}</div>
      <div class="service">
        <van-grid :border="false">
          <van-grid-item
            v-for="item in freeList"
            :key="item.id"
            :icon="item.url"
            :text="item.value"
           />
        </van-grid>
      </div>
    </div>
    <div class="bot list">
      <h3>使用须知</h3>
      <div><span>安检前后</span>{{ productInfo.useAddress_id }}</div>
      <div><span>有效期</span>购买之日起{{ productInfo.validTime }}天内有效</div>
      <div><span>使用时长</span>{{ productInfo.useMinute }}分钟/人次</div>
      <h3 class="detail">详情介绍</h3>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return{
      productInfo: '',
      img: '',
      freeList: ''
    }
  },
  mounted() {
    this.productInfo = JSON.parse(this.$route.query.info)
    this.img = this.$route.query.img
    this.freeList = JSON.parse(this.productInfo.serverContent).defaultfreeList
  }
}
</script>

<style lang="less" scoped>
.qrcode-detail{
  background: #f5f5f5;
  >img{
    width: 100%;
    height: 220px;
    background: #9b9b9b;
  }
  .list{
    background: #fff;
    h3{
      font-size: 18px;
      margin-bottom: 20px;
    }
    div{
      font-size: 14px;
      padding: 4px 0;
      >span{
        color: #9b9b9b;
        font-weight: normal;
        display: inline-block;
        width: 70px;
      }
    }
  }
  .top{
    padding: 20px 18px;
    box-sizing: border-box;
  }
  .bot{
    padding: 30px 18px 20px;
    box-sizing: border-box;
    margin-top: 10px;

    .detail{
      margin-top: 30px;
    }
  }
}
</style>
